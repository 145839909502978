<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <div class="close-modal" @click="closeModal(false)" style="top: 10px;">
        <uil-multiply size="20px" />
      </div>
    </ion-toolbar>
  </ion-header>

  <ion-content color="white" class="ion-padding" scroll-y="true">
    <div>
      <ion-input placeholder="Enter section name" v-model="inputData" />
      <div class="cannot-text">The section name cannot be the same as another section.</div>
    </div>
    <div class="btn-container">
      <ion-button class="" @click="saveModal(inputData)">Save</ion-button>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, modalController, IonInput, IonHeader, IonToolbar } from '@ionic/vue';
import { UilMultiply } from '@iconscout/vue-unicons'

export default {
  components: { IonContent, UilMultiply, IonInput, IonHeader, IonToolbar },
  props: {
    title: { type: String, default: 'Section Name' },
    section_name: { type: String, default: '' },
  },
  data () {
    return {
      inputData: ""
    }
  },
  setup () {
    const saveModal = (name) => {
      modalController.dismiss(name);
    };

    const closeModal = (close) => {
      modalController.dismiss(close);
    };


    return { saveModal, closeModal };
  },
  mounted () {
    this.inputData = this.section_name
  }
};
</script>

<style scoped lang="scss">
ion-input {
  border: 1px solid #373636;
  --placeholder-color: #373636;
  --color: #373636;
  --padding-bottom: 10px;
  --padding-top: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-button {
  margin-top: 10px;
  margin-left: 0px;
}

.btn-container {
  text-align: center;
}

.cannot-text {
  color: #373636;
  margin: 10px 0px;
  font-size: 12px;
  text-align: center;
}
</style>
