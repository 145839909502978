<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="ios hydrated container-page-header">
        <div class="page-title">
          <div class="orange-circle" @click="backPage">
            <uil-angle-left size="25px" />
          </div>
          <h1>Sections</h1>
        </div>

        <div class="sections-container" v-if="!loading">
          <div v-for="section in getSortedReportElements(sections)" :key="section.id" class="section-container">
            <div class="section-circle" @click="openJob(jobId, section)">
              <svg
                class="progress-ring"
                width="90"
                height="90"
              >
                <circle
                  class="progress-ring__circle"
                  :style="circleStyle(section)"
                  stroke="red"
                  stroke-width="3"
                  fill="transparent"
                  r="40"
                  cx="45"
                  cy="45"
                />
              </svg>
              <div class="section-name">
                <component class="orange-icon" size="50px" :is="getSectionComponent(section)" />
              </div>
              <template v-if="jobsProgress.bySection[section.id].canSubmit">
                <uis-check-circle size="20px" class="section-tick" />
              </template>
            </div>
            <div class="section-title">
              {{ section.name }}
            </div>
          </div>
        </div>

        <div class="page-title extra-margin">
          <h2>Add Section</h2>
        </div>

        <div class="sections-container">
          <template v-if="repeatableSections.length">
            <div v-for="section in getSortedReportElements(repeatableSections)" :key="section.id" class="section-container add-section">
              <div class="section-circle">
                <div class="section-name">
                  <component size="60px" :is="section.component" />
                </div>
                <div class="add-section-btn" @click="addSection(section)">
                  <div class="orange-circle">
                    <uil-plus size="20px" />
                  </div>
                  <div class="add-text">Add</div>
                </div>
              </div>
              <div class="section-title">
                {{ section.name }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="no-message">There are no repeatable sections that can be added</div>
          </template>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, modalController } from '@ionic/vue';
import { UilPlus, UilQuestion, UilAngleLeft } from '@iconscout/vue-unicons'
import {defineAsyncComponent} from "vue";
import SectionName from '@/components/modals/SectionName';
import { UisCheckCircle } from '@iconscout/vue-unicons-solid'
import storeMixin from '@/store/component-mixin';
import mutatesJobsMixin from "@/mixins/mutates-jobs";

export default {
  mixins: [
    storeMixin,
    mutatesJobsMixin,
  ],
  components: {
    IonContent,
    IonPage,
    UilPlus, UilQuestion, UilAngleLeft, UisCheckCircle
  },
  data () {
    return {
      modal: null,
      loading: false,
    }
  },
  computed: {
    jobId () {
      return this.$route.params.id;
    },
    repeatableSections () {
      return this.sections
        ? Object.values(this.sections).filter(section => !!section.repeatable)
        : [];
    },
  },
  methods: {
    openJob (jobId, section) {
      this.$router.push({ path: '/job/'+jobId+'/section/' + section.id })
    },
    async addSection (section) {
      let newSection = JSON.parse(JSON.stringify(section));

      newSection.id = this.getNextNegativeId(this.sections);

      const modal = await modalController
        .create({
          component: SectionName,
          cssClass: 'section-modal',
          componentProps: {
            title: 'Section Name',
            section_name: section.name
          },
        })

      modal.onDidDismiss().then((data) => {
        if (data.data && data.data != section.name) {
          newSection.name = data.data;
          newSection.repeatable = 0;
          newSection.component = defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${newSection.icon_import}.vue`))

          let reKeyedItems = {};
          for (let itemKey in newSection.items) {
            let item = newSection.items[itemKey];
            item.id = this.getNextNegativeId(newSection.items);
            reKeyedItems[item.id] = item;

            let questionIdMap = {};
            let reKeyedQuestions = {};

            for (let questionKey in item.questions) {
              let question = item.questions[questionKey];
              let originalId = question.id;

              question.id = this.getNextNegativeId(item.questions);
              question.old_id = originalId;

              questionIdMap[originalId] = question.id;
              reKeyedQuestions[question.id] = question;

              question.answer.answer = null;
              question.answer.date_answered = null;
              question.answer.id = 0;
              question.answer.answered_by = null;

              question.answer_note.type = null;
              question.answer_note.text = null;
              question.answer_note.image = [];
              question.answer_note.video = [];
            }

            for (let questionKey in item.questions) {
              let question = item.questions[questionKey];
              if (question['conditional']) {
                // get copied question
                let q = item.questions[question.old_id];
                q.conditional = [
                  questionIdMap[q.conditional[0]],
                  q.conditional[1],
                  q.conditional[2],
                ];
              }
            }

            item.questions = reKeyedQuestions;
          }
          newSection.items = reKeyedItems;

          this.sections[newSection.id] = newSection;
        }
      });

      return modal.present();
    },
    getSectionComponent (section) {
      let icon = section.icon_import;
      icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
      return defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${icon}.vue`))
    },
    backPage () {
      this.$router.back()
    },
  },
  beforeUpdate () {
    this.loading = true;
  },
  updated () {
    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
h1, h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
}

.sections-container {
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  .section-container {
    margin-bottom: 10px;
    &.add-section {
      .section-circle {
        height: 90px;
        width: 90px;
        .section-name {
          color: #CBCACA;
        }

        .add-section-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 800;
          font-family: 'Raleway', sans-serif;
          z-index: 10;
          .orange-circle {
            height: 20px;
            width: 20px;
            padding: 0;
          }
          .add-text {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 800;
          }
        }
      }

      .section-title {
        color: #373636;
      }
    }
    .section-circle {
      background-color: #ffffff;
      border-radius: 50%;
      height: 90px;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      text-align: center;
      font-size: 12px;
      box-shadow: rgb(0 0 0 / 12%) 0 4px 16px;
      position: relative;
      .section-name {
        position: absolute;
        padding: 0 8px;
      }
      svg.orange-icon {
        color: #DB9123;
      }
    }

    .orange-circle {
      height: 45px;
      width: 45px;
    }
    .section-title {
      text-align: center;
      font-size: 14px;
      font-weight: 800;
      padding: 0 5px;
    }
  }
}

.extra-margin {
  margin-top: 40px;
}

.progress-ring {
  position: absolute;
}

.progress-ring__circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.no-message {
  text-align: center;
  width: 60%;
}

.page-title {
  position: relative;
  .orange-circle {
    justify-content: center;
    align-items: center;
    padding: 0;
    position: absolute;
    left: 20px;
    color: black;
  }
}

.section-tick {
  position: absolute;
  right: 0;
  bottom: 0;
  fill: #1D9F67;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
}
</style>
